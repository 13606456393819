import React from "react"
import { graphql, StaticQuery } from "gatsby"
import FormContato from "../components/formContato"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"



export default function ContatoPage() {
  
  return (
  <Layout title="Denise Barbosa">
      <SEO
        title="Contato"
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
        
          <h3>Vou adorar falar com você!</h3>
      
          <FormContato />
        </div>
      </article>
    </Layout>
  )
}
